const fixImageUrl = (url) => {
  const base = process.env.IMAGE_URL;

  if (url && url.includes('/globalassets/')) {
    return `${base}${url.substring(url.indexOf("/globalassets/"))}`
  }

  if (url && url.includes('/contentassets/')) {
    return `${base}${url.substring(url.indexOf("/contentassets/"))}`
  }

  return url
}

module.exports = fixImageUrl
